<template>
  <v-container
    style="width:100%;padding-top: 0px;
    padding-bottom: 0px;"
  >
    <v-card class="pa-1" max-width="100%" style="" elevation="0">
      <v-data-table
        :headers="headers"
        :items="lista"
        :loading="loading_table"
        disable-pagination
        no-data-text="Nenhuma conta a vencendo hoje!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-0 fundo_tabela"
        :hide-default-footer="true"
      >
        <template v-slot:item.vlr_total="{ item }">
          <span>{{ formatar_moeda(item.vlr_total) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
var moment = require("moment");
import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "saldoPrincipal",
  props: {
    source: String,
  },
  mixins: [formata],
  mounted() {
    this.buscaContasPagar();
  },
  data: (vm) => ({
    loading_table: false,
    barSaldos: true,
    saldos: [],
    selectedItem: 0,
    lista: [],
    data_inicial: moment().format("YYYY-MM-DD"),
    data_final: moment().format("YYYY-MM-DD"),
    headers: [
      {
        text: "LANÇAMENTOS VENCENDO HOJE",
        value: "descricao",
        sortable: false,
      },

      {
        text: "",
        value: "vlr_total",
        align: "right",
        sortable: false,
      },
    ],
  }),
  computed: {},

  methods: {
    formatar_valor_check(val) {
      //  console.log(val);
      if (val > 0) {
        this.pos = true;
        this.neg = false;
      } else {
        this.pos = false;
        this.neg = true;
      }
    },

    buscaContasPagar() {
      this.loading_table = true;
      axios
        .post(
          `/fin/lancamento/contas_pagar`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.data_inicial,
            data_final: this.data_final,
            tipo_data: "vencimento",
          },
          auth
        )
        .then((response) => {
          this.lista = response.data;
          this.loading_table = false;
        });
    },
  },
};
</script>
<style scoped>
.cancelada {
  color: red;
}
.ativa {
}
.negativo {
  color: #d90000;
  font-weight: 700;
  font-size: 1.5em;
}
.desativado {
  /*border: 2px solid #000 !important;*/
  border-right: 2px solid #c5c3c3;
}
.positivo {
  color: #3e5286;
  font-weight: 700;
  font-size: 1.5em;
}
</style>

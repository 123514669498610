<template>
  <v-main style="background-color:#f1f4f2;" class="ml-0 mt-2 mr-0">
    <div class="ml-2">
      <v-card max-width="100%" elevation="2">
        <v-row class="mx-auto mt-0 ml-0 pa-0">
          <v-col cols="12" xl="9" lg="9" md="9" sm="9" xs="12">
            <v-row class="mx-auto mt-0 ml-5 pa-0">
              <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <grafico-creditos-debitos></grafico-creditos-debitos>
              </v-col>
            </v-row>
            <v-row class="mx-auto mt-0 ml-0 pa-1">
              <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <grafico-creditos-debitos-proximos></grafico-creditos-debitos-proximos>
              </v-col>
            </v-row>

            <v-row class="mx-auto mt-0 ml-0 pa-1">
              <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                <grafico-participacao-grupo></grafico-participacao-grupo>
              </v-col>
              <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
                <lista-vence-hoje></lista-vence-hoje>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            style="background-color:#f1f4f2;"
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="12"
          >
            <v-row
              ><v-col
                class="pt-5"
                style=""
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                ><p style="align:left;font-size:1rem;font-weight:600">
                  MINHAS CONTAS
                </p></v-col
              >
            </v-row>
            <v-row
              ><v-col style="" cols="12" xl="12" lg="12" md="12" sm="12" xs="12"
                ><saldo-principal></saldo-principal
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import GraficoCreditosDebitos from "./GraficoCreditosDebitos.vue";
import GraficoCreditosDebitosProximos from "./GraficoCreditosDebitosProximos.vue";
import GraficoParticipacaoGrupo from "./GraficoParticipacaoGrupo.vue";
import ListaVenceHoje from "./ListaVenceHoje.vue";
import SaldoPrincipal from "./SaldoPrincipal.vue";

export default {
  name: "App",
  components: {
    GraficoCreditosDebitos,

    SaldoPrincipal,
    GraficoCreditosDebitosProximos,
    GraficoParticipacaoGrupo,
    ListaVenceHoje,
  },
  data() {
    return {
      chartData: {
        Books: 24,
        Magazine: 30,
        Newspapers: 10,
      },
    };
  },
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  background-color: #f1f4f2;
}
</style>

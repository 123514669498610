<template>
  <v-container
    style="width:100%;padding-top: 0px;
    padding-bottom: 0px; background-color:#f1f4f2!important"
  >
    <v-card
      class="pa-2"
      style="background-color:#f1f4f2!important"
      max-width="100%"
      elevation="0"
    >
      <v-progress-circular
        v-show="barSaldos"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-circular>
      <v-list three-line style="background-color:#f1f4f2!important">
        <template v-for="(item, i) in saldos">
          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><p
                  style="font-weight:bold;text-align:right;color:#8C8C8C;font-size:15px"
                >
                  {{ item.nome_banco }}
                </p>
                <p
                  style="color:#8C8C8C;text-align:right;font-size:1.0em;line-height:10px"
                >
                  {{ item.conta }}
                </p></v-list-item-title
              >
              <v-list-item-subtitle
                ><p :class="item.class" style="text-align:right;font-size:13px">
                  R$ {{ formatar_valor(item.saldo) }}
                </p></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div
        class="text--white"
        style=" width:100%;float:right;margin-right:0px;padding:20px;padding-top:5px;font-weight:bolder;text-align:right;font-size:16px;color:#FFF;background-color:#1d7335;height:74px"
      >
        <p>SALDO GERAL</p>
        <p>R${{ formatar_valor(saldo_total) }}</p>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "saldoPrincipal",
  props: {
    source: String,
  },
  mixins: [formata],
  mounted() {
    this.buscaSaldos();
    sessionStorage.setItem("selconta", "3");
  },
  data: (vm) => ({
    barSaldos: true,
    saldos: [],
    selectedItem: 0,
    saldo_total: 0,
  }),
  computed: {},

  watch: {},
  methods: {
    formatar_valor_check(val) {
      //  console.log(val);
      if (val > 0) {
        this.pos = true;
        this.neg = false;
      } else {
        this.pos = false;
        this.neg = true;
      }
    },
    selecionarConta(conta, index) {
      console.log("conta=" + conta);
      console.log("index=" + index);
      //this.$emit("changeTab", "tab1");
      //console.log("index " + index);
      // this.$emit("selecionaConta", id);
      //  setTimeout(() => (this.selectedItem = parseInt(index)), 5);
      //  sessionStorage.setItem("selconta", id);
      //  sessionStorage.setItem("selindex", index);
    },
    buscaSaldos() {
      axios
        .post(
          `/fin/conta/saldos`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.saldos = response.data.lista;
          this.saldo_total = response.data.saldo_total;

          //this.selecionarConta(3, 1);

          this.barSaldos = false;
        });
    },
  },
};
</script>
<style scoped>
.cancelada {
  color: red;
}
.ativa {
}
.negativo {
  color: #d90000;
  font-weight: 700;
  font-size: 1.5em;
}
.desativado {
  /*border: 2px solid #000 !important;*/
  border-right: 2px solid #c5c3c3;
}
.positivo {
  color: #187052;
  font-weight: 700;
  font-size: 1.5em;
}
</style>

<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  data: () => ({
    dias: [],
    debitos: [],
    creditos: [],
  }),
  mounted() {
    this.buscaDados();
    /* this.addPlugin({
      id: "chart-plugin",
      afterDraw: function(chart) {
        var c = chart.canvas;
        var ctx = c.getContext("2d");
        ctx.fillStyle = "#ff0000";
        ctx.beginPath();
        let xPosition = 72; //x positioning to be calculated according to your needs
        let yPosition = c.height - 28;
        ctx.arc(xPosition, yPosition, 3, 0, 2 * Math.PI);
        ctx.fill();
        datalabels: {
          formatter: (value, ctx) => {
            return "$" + value;
          };
        }
      },
    });*/
  },
  methods: {
    buscaDados() {
      this.loading = true;
      axios
        .post(`/fin/grafico/30dias`, {
          cnpj: window.sessionStorage.getItem("cnpj"),
        })
        .then((response) => {
          if (response.data.error) {
            this.textSnack = response.data.error;
            this.snackbar = true;
          } else {
            this.creditos = response.data.creditos;
            this.debitos = response.data.debitos;
            this.dias = response.data.data;

            this.renderChart(
              {
                labels: this.dias,
                datasets: [
                  {
                    label: "Créditos",
                    backgroundColor: "#5C9B62",
                    borderColor: "#5C9B62",
                    data: this.creditos,
                  },
                  {
                    label: "Débitos",
                    backgroundColor: "#b71c1c",
                    borderColor: "#b71c1c",
                    data: this.debitos,
                  },
                ],
              },
              {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 4,
                tooltips: {
                  callbacks: {
                    label: function(tooltipItem, data) {
                      return (
                        "R$ " +
                        Number(tooltipItem.yLabel)
                          .toFixed(2)
                          .replace(/./g, function(c, i, a) {
                            return i > 0 &&
                              c !== "." &&
                              (a.length - i) % 3 === 0
                              ? "." + c
                              : c;
                          })
                      );
                    },
                  },
                },
                title: {
                  display: true,
                  text: "Visão Geral dos Últimos 30 dias",
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function(value, index, values) {
                          if (parseInt(value) >= 1000) {
                            return (
                              "R$" +
                              value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            );
                          } else {
                            return "R$" + value;
                          }
                        },
                      },
                      gridLines: {
                        display: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
              }
            );
          }
          this.loading = false;
        });
    },
  },
  renderiza() {},
};
</script>

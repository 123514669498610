<script>
import { Line } from "vue-chartjs";
import { formata } from "../plugins/format";

export default {
  extends: Line,
  mixins: [formata],
  data: () => ({
    dias: [],
    debitos: [],
    creditos: [],
  }),

  mounted() {
    this.buscaDados();
  },
  methods: {
    buscaDados() {
      this.loading = true;
      axios
        .post(`/fin/grafico/prox60dias`, {
          cnpj: window.sessionStorage.getItem("cnpj"),
        })
        .then((response) => {
          if (response.data.error) {
            this.textSnack = response.data.error;
            this.snackbar = true;
          } else {
            this.creditos = response.data.creditos;
            this.debitos = response.data.debitos;
            this.dias = response.data.data;

            this.renderChart(
              {
                labels: this.dias,
                datasets: [
                  {
                    label: "Créditos",
                    backgroundColor: "transparent",
                    borderColor: "#5C9B62",
                    pointBackgroundColor: "#5C9B62",
                    data: this.creditos,
                  },
                  {
                    label: "Débitos",
                    backgroundColor: "transparent",
                    borderColor: "#b71c1c",
                    pointBackgroundColor: "#b71c1c",
                    data: this.debitos,
                  },
                ],
              },
              {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                  display: true,
                  text: "Visão Geral dos Próximos 60 dias",
                },

                tooltips: {
                  callbacks: {
                    label: function(tooltipItem, data) {
                      return (
                        "R$ " +
                        Number(tooltipItem.yLabel)
                          .toFixed(2)
                          .replace(/./g, function(c, i, a) {
                            return i > 0 &&
                              c !== "." &&
                              (a.length - i) % 3 === 0
                              ? "." + c
                              : c;
                          })
                      );
                    },
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function(value, index, values) {
                          if (parseInt(value) >= 1000) {
                            return (
                              "R$" +
                              value
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            );
                          } else {
                            return "R$" + value;
                          }
                        },
                      },
                      gridLines: {
                        display: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
                legend: {
                  display: true,
                },
                responsive: true,
                maintainAspectRatio: false,
              }
            );
          }
          this.loading = false;
        });
    },
  },
};
</script>

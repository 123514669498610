<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  data: () => ({
    dias: [],
    debitos: [],
    creditos: [],
  }),
  mounted() {
    this.buscaDados();
  },
  methods: {
    buscaDados() {
      this.loading = true;
      axios
        .post(`/fin/grafico/30dias_perc`, {
          cnpj: window.sessionStorage.getItem("cnpj"),
        })
        .then((response) => {
          if (response.data.error) {
            this.textSnack = response.data.error;
            this.snackbar = true;
          } else {
            this.creditos = response.data.credito;
            this.debitos = response.data.debito;

            this.renderChart(
              {
                labels: ["Créditos", "Débitos"],
                datasets: [
                  {
                    backgroundColor: ["#5C9B62", "#b71c1c"],
                    borderColor: ["#5C9B62", "#b71c1c"],
                    data: [this.creditos, this.debitos],
                  },
                ],
              },

              {
                cutoutPercentage: 70,

                responsive: true,
                maintainAspectRatio: false,

                title: {
                  display: true,
                  text: "Movimentação - Últimos 30 dias",
                },
              }
            );
            /* this.addPlugin({
              id: "chart-plugin",
              beforeInit: function(chart) {
                datalabels: {
                  formatter: (value, context) => {
                    return "$" + value;
                  };
                }
              },
            });*/
          }
          this.loading = false;
        });
    },
  },
};
</script>
